import { useMediaQuery } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import hpg_right from "../../../assets/images/hpg_right.png";

import useStyles from "./style";
import { useTheme } from "@material-ui/styles";
import Bullets from "../Bullets";
import $ from "jquery";

const Homepage = props => {
  const { history, location } = props;
  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  return (
    <div className={"homepage"}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={"leftPanel slide-down-fade-in"}
        >
          <div className={"content"}>
            <h1>EVERY BIT OF REAL ESTATE</h1>
            <p>
              research <span>/</span> analysis <span>/</span> visualization
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={"rightPanel slide-up-fade-in"}
          style={{
            backgroundImage: `url(${hpg_right})`,
            backgroundPosition: `center center`,
            backgroundSize: `cover`
          }}
        ></Grid>
      </Grid>

      <Bullets location={location}></Bullets>
    </div>
  );
};

export default Homepage;
