import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    "& h2": {
      textAlign: `center`,
      color: `#ff3366`,
      fontSize: `55px`,
      fontWeight: `800`,
      letterSpacing: `5.5px`,
      lineHeight: `70px`,
      textTransform: `uppercase`,
      margin: `3rem 0`
    }
  },

  contact: {
    maxWidth: `1400px`,
    margin: `3rem auto !important`,
    padding: `1rem`,
    position: `relative`
  },

  pinkBox: {
    backgroundColor: `#ff3366`,
    padding: `3rem`,
    borderRadius: `20px`,

    "& h3": {
      /* Style for "Or write d" */
      color: `#06070d`,
      fontSize: `35px`,
      letterSpacing: `3.5px`,
      lineHeight: `45px`,
      fontFamily: `Helvetica`
    },

    "& ul": {
      margin: `5rem 0 2rem`
    },

    "& li": {
      listStyle: `none`,
      color: `#ececec`,
      fontFamily: `Poppins`,
      fontSize: `13px`,
      fontWeight: `500`,
      letterSpacing: `1.3px`,
      lineHeight: `25px`
    },

    "& .mb2": {
      marginBottom: `2rem`
    }
  },

  form: {
    margin: `3rem 0`,
    "& .MuiPaper-root": {
      background: `transparent`,
      border: 0,
      boxShadow: `none`
    }
  },
  formCards: {},

  textField: {
    "& input, & .MuiInputBase-root": {
      background: `#fff`,
      border: 0,
      boxShadow: `none`,
      color: `#000`,
      fontFamily: `Poppins`
    },

    "& fieldset": {
      background: `transparent`,
      border: 0,
      boxShadow: `none`,
      color: `#000`
    },

    margin: `0 0 2rem 0`
  },
  contactButton: {
    margin: `2rem 0`,
    display: `block`,
    padding: `1rem 3rem`,
    borderRadius: `20px`,
    fontSize: `18px`,
    letterSpacing: `1.5px`,
    fontWeight: `bold`,

    "&.MuiButton-containedPrimary": {
      backgroundColor: `#5c5cff`,
      color: `#fff`
    }
  },

  footer: {
    borderTop: `3px solid #fff`,
    textAlign: `center`,
    color: `#797a7d`,
    "& p": { fontFamily: `Poppins`, padding: `0 0 1rem` }
  },

  copy: {
    margin: `3rem auto 1rem`,
    width: `150px`
  }
}));

export default useStyles;
