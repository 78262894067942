import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: `1400px`,
    // margin: `3rem auto !important`,
    // padding: `0 1rem`,
    "@media (min-width: 960px)": {
      position: `relative`
      // top: `112px`
    },

    "& *": {
      // fontFamily: `Arial`
      fontFamily: `Helvetica`
    },
    "& h1": {
      // fontSize: `3rem`,
      // maxWidth: `500px`,
      // color: `#151b74`,
      // margin: `3rem 0 1rem`,
      color: `#ececec`,
      fontFamily: `Helvetica`,
      fontSize: `50px`,
      fontWeight: `800`,
      letterSpacing: `5.5px`,
      lineHeight: `70px`,
      textTransform: `uppercase`,
      margin: `13rem 0 8rem`,

      "@media (max-width: 959px)": {
        margin: `3rem 0`,
        textAlign: `center`
      }
    },

    "& h2": {
      // fontWeight: `normal`,
      // fontSize: `1.3rem`,
      margin: `0 0 2rem`,
      color: `#ff3366`,
      fontFamily: `Helvetica`,
      fontSize: `20px`,
      fontWeight: `700`,
      letterSpacing: `2px`,
      lineHeight: `25px`,

      "@media (max-width: 959px)": {
        // margin: `3rem 0`,
        textAlign: `center`
      }
    }
  },

  noPadding: {
    padding: `0 !important`
  }
}));

export default useStyles;
