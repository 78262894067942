import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@material-ui/styles";
import validate from "validate.js";

import theme from "./theme";
import "./assets/scss/index.scss";
import validators from "./common/validators";
import Routes from "./Routes";
import UAParser from "ua-parser-js";
import { debounce } from "lodash";

const parser = new UAParser();
const isMobile = parser.getDevice();

console.log();

const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  constructor() {
    super();
    if (isMobile.type !== "mobile") {
      document.addEventListener("wheel", debounce(this.wheelIntent, 200));
    }

    window.addEventListener("scroll", this.listenToScroll);
  }

  wheelIntent = event => {
    const direction = event.deltaY;

    if (direction < 0) {
      this.navigateToPrevLink();
    } else {
      this.navigateToNextLink();
    }
  };

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    if (scrolled === 0) {
      this.navigateToPrevLink();
    }

    if (winScroll === height) {
      this.navigateToNextLink();
    }
  };

  navigateToPrevLink = () => {
    switch (this.getPageLocation()) {
      case "/":
        break;
      case "/report-excerpt":
        browserHistory.push("/");
        break;
      case "/find-out-more":
        browserHistory.push("/report-excerpt");
        break;
      default:
        break;
    }
  };

  navigateToNextLink = () => {
    switch (this.getPageLocation()) {
      case "/":
        browserHistory.push("/report-excerpt");
        break;
      case "/report-excerpt":
        browserHistory.push("/find-out-more");
        break;
      case "/find-out-more":
        break;
      default:
        break;
    }
  };

  getPageLocation = () => {
    return browserHistory.location.pathname;
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    );
  }
}
