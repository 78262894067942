import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Website as WebsiteLayout } from "./layouts";

import { Homepage, NotFound, ReportExcerpt, FindOutMore, TestNavigator } from "./views";

const Routes = () => {
  return (
    <Switch>
      {/* ===== PUBLIC ===== */}
      <RouteWithLayout
        component={Homepage}
        exact
        layout={WebsiteLayout}
        path="/"
      />

      <RouteWithLayout
        component={ReportExcerpt}
        exact
        layout={WebsiteLayout}
        path="/report-excerpt"
      />

      <RouteWithLayout
        component={FindOutMore}
        exact
        layout={WebsiteLayout}
        path="/find-out-more"
      />

        <RouteWithLayout
            component={TestNavigator}
            exact
            layout={WebsiteLayout}
            path="/test-navigator"
        />

      <RouteWithLayout
        component={NotFound}
        exact
        layout={WebsiteLayout}
        path="/404"
      />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
