import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import useStyles from "./style";
import { useTheme } from "@material-ui/styles";
import hpg_right from "../../../assets/images/hpg_right.png";
import findOutRight from "../../../assets/images/clarity.png";
import logo from "../../../assets/images/logo.png";
import Bullets from "../Bullets";
import Contact from "./Contact/_contactNew";


const FindOutMore = props => {
  const { history, location } = props;
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 10);
  }, []);

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  return (
    <div className={"findOutMore"}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={"leftPanel slide-down-fade-in"}
        >
          <img src={logo} className={`logo`} alt={`Mavers Analytics`} />
          <div className={`content`}>
            <h1>Find out more</h1>
            <h2>Knowledge is power</h2>
            <Contact />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={"rightPanel slide-up-fade-in"}
          style={{
            backgroundImage: `url(${findOutRight})`,
            backgroundPosition: `center center`,
            backgroundSize: `cover`
          }}
        ></Grid>
      </Grid>

      <Bullets location={location}></Bullets>
    </div>
  );
};

export default FindOutMore;
