import axios from "axios";

let url;

if (
  process &&
  process.env &&
  process.env.NODE_ENV &&
  process.env.NODE_ENV === "development"
) {
  url = "http://localhost:5000/v1";
} else {
  url = "http://maversanalytics.com";
}

export const rURL = url;

export const MEDIA_PATH = "http://localhost:5000/static/media";
export const IMG_PATH = "http://localhost:5000/static/images";

export const REST = axios.create({
  baseURL: url,
  timeout: 10000,
  headers: { "X-engage-initiator": "frontend" }
});

export const REST2 = axios.create({
  baseURL: url,
  timeout: 20000,
  headers: { "X-engage-initiator": "frontend" }
});

export const xhr = data => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(data.method, data.url);
    xhr.onload = () => resolve(xhr);
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  });
};

export const simulateClick = elem => {
  console.log(elem)
  // Create our event (with options)
  const evt = new MouseEvent("click", {
    bubbles: true,
    cancelable: true,
    view: window
  });
  // If cancelled, don't dispatch our event
  const canceled = !elem.dispatchEvent(evt);
};
