import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { REST, rURL, xhr } from "Utils";
import useStyles from "./contact.style";
import validate from "validate.js";
import schema from "./schema";
import $ from "jquery";
import UAParser from "ua-parser-js";

const parser = new UAParser();

const OS = parser.getOS().name;
const OSversion = parser.getOS().version;

const isBadOS = OS === "iOS" && parseFloat(OSversion) < 14.1;

const Contact = () => {
  const classes = useStyles();

  const [sent, setSent] = useState(null);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    let url;

    if (formState.isValid) {
      url = `${rURL}/send/index.php`;

      // console.log(JSON.stringify(formState.values));

      $.ajax({
        url: url,
        // type: "GET",
        data: {
          name: formState.values.name,
          email: formState.values.email
        },
        success: function(result) {
          setSent(true);
        }
      });
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      <div className={classes.form}>
        {sent === true && (
          <h4>
            Thank you! <br /> We will get back to you shortly!
          </h4>
        )}
        {!sent && (
          <form onSubmit={handleSubmit}>
            <div>
              <TextField
                className={classes.textField}
                error={hasError("name")}
                fullWidth
                helperText={hasError("name") ? formState.errors.name[0] : null}
                label={"Name"}
                name="name"
                onChange={handleChange}
                type="text"
                value={formState.values.name || ""}
                variant="outlined"
              />

              <TextField
                className={classes.textField}
                error={hasError("email")}
                fullWidth
                helperText={
                  hasError("email") ? formState.errors.email[0] : null
                }
                label={"Email"}
                name="email"
                onChange={handleChange}
                type="email"
                value={formState.values.email || ""}
                variant="outlined"
              />
            </div>

            <div>
              {!isBadOS && formState.isValid && (
                <Button
                  // className={classes.contactButton}
                  className={`enabledBtn`}
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  to={"media"}
                >
                  Stay in the game
                </Button>
              )}
              {!isBadOS && !formState.isValid && (
                <div className={`disabledBtn`}>Stay in the game</div>
              )}

              {isBadOS && !formState.isValid && (
                <a
                  className={`disabledBtn`}
                  href={`#`}
                  title={`Click to mail us`}
                >
                  Stay in the game
                </a>
              )}

              {isBadOS && formState.isValid && (
                <a
                  className={`enabledBtn`}
                  href={`mailto:radu.gogoasa@themavers.com?subject=MaversAnalyticsForm&body=Hi. This is my email address: ${formState.values.email}`}
                  title={`Click to mail us`}
                >
                  Stay in the game
                </a>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Contact;
