import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";

import useStyles from "./style";
import { useTheme } from "@material-ui/styles";
import hpg_right from "../../../assets/images/hpg_right.png";
import Bullets from "../Bullets";
import { simulateClick } from "../../../Utils/index";
import reportExcerptLeft from "../../../assets/images/reportExcerptLeft.png";
import reportExcerptRight from "../../../assets/images/reportExcerptRight.png";
import logoDark from "../../../assets/images/logoDark.svg";

const ReportExcerpt = props => {
  const { history, location } = props;
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 10);
  }, []);

  return (
    <div className={"reportExcerpt"}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={"leftPanel slide-down-fade-in"}
          style={{
            backgroundImage: `url(${reportExcerptLeft})`,
            backgroundPosition: `center center`,
            backgroundSize: `cover`
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={"rightPanel slide-up-fade-in"}
        >
          <img src={logoDark} className={`logoDark`} alt={"Mavers Logo"} />

          <p className={`m-t-12rem`}>
            In a world driven by the ability to gather and process data, having
            access to knowledge has become <strong>THE</strong> competitive
            advantage.
          </p>
          <p>
            <strong>Mavers Analytics</strong> has developed a <strong>Knowledge
            Management Engine (KME) </strong> using our team’s vast real estate experience
            and the brightest minds in data science and business intelligence.
          </p>
          <p>
            Processing every <strong>BIT</strong> of data, employing proprietary
            stochastic algorithms on top of traditional statistical analytics
            with exceptional clarity, we provide a breakthrough approach, with
            our KME continuously scaning multiple public (and on demand,
            licensed) databases offering complete market, trend analysis and
            location analytics reports with an extensive level of detail.
          </p>
        </Grid>
      </Grid>

      <Bullets location={location}></Bullets>
    </div>
  );
};

export default ReportExcerpt;
