import React from "react";
import { Link } from "react-router-dom";

const Bullets = props => {
  const { location } = props;

  return (
    <div className={"bullets"}>
      <li className={location.pathname === "/" ? "active" : ""}>
        <Link id={`hpg-link`} className={"linkElement"} to={"/"}>
          &nbsp;
        </Link>
      </li>
      <li className={location.pathname === "/report-excerpt" ? "active" : ""}>
        <Link
          id={`report-link`}
          className={"linkElement"}
          to={"/report-excerpt"}
        >
          &nbsp;
        </Link>
      </li>
      <li className={location.pathname === "/find-out-more" ? "active" : ""}>
        <Link
          id={`contact-link`}
          className={"linkElement"}
          to={"/find-out-more"}
        >
          &nbsp;
        </Link>
      </li>
    </div>
  );
};

export default Bullets;
